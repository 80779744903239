import React from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AuthRouter from "./modules/core/accounts/routers/AuthRouter";
import LayoutRouter from "./modules/core/layout/routers/LayoutRouter";
import DashboardRouter from "./modules/dashboard/routers/DashboardRouter";
import TeamsRouter from "./modules/core/teams/routers/TeamsRouter";
import NotificationsRouter from "./modules/core/notifications/routers/NotificationsRouter";
import NotFound from "./modules/pages/components/NotFound";

const AppRouter = () => {
  const auth = useSelector((state) => state.auth);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            auth.isAuthenticated ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/account/login" />
            )
          }
        />
        {/* Account specific routes */}
        <Route path="/account/*" element={<AuthRouter />} />
        {/* Layout routes */}
        <Route path="/layout/*" element={<LayoutRouter />} />
        {/* Dashboard or other routes */}
        <Route path="/dashboard/*" element={<DashboardRouter />} />
        {/* Teams routes */}
        <Route path="/teams/*" element={<TeamsRouter />} />
        {/* Notifications routes */}
        <Route path="/notifications/*" element={<NotificationsRouter />} />
        {/* Catch-all route for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;

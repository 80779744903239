import { combineReducers } from "redux";
import authReducer from "./core/accounts/reducers/authReducer";
import layoutReducer from "./core/layout/reducers/layoutReducer";
import teamsReducer from "./core/teams/reducers/teamsReducer";
import notificationsReducer from "./core/notifications/reducers/notificationsReducer";

// Combine all your reducers into one root reducer
const rootReducer = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  teams: teamsReducer,
  notifications: notificationsReducer,
  // Add other reducers here
});

export default rootReducer;

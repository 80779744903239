import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import translation files from each module
import accountsEN from "./modules/core/accounts/locales/en.json";
import accountsNL from "./modules/core/accounts/locales/nl.json";

import notificationsEN from "./modules/core/notifications/locales/en.json";
import notificationsNL from "./modules/core/notifications/locales/nl.json";

import dashboardEN from "./modules/dashboard/locales/en.json";
import dashboardNL from "./modules/dashboard/locales/nl.json";

import layoutEN from "./modules/core/layout/locales/en.json";
import layoutNL from "./modules/core/layout/locales/nl.json";

import pagesEN from "./modules/pages/locales/en.json";
import pagesNL from "./modules/pages/locales/nl.json";

import teamsEN from "./modules/core/teams/locales/en.json";
import teamsNL from "./modules/core/teams/locales/nl.json";

// Get saved language from localStorage or default to 'en'
const savedLanguage = localStorage.getItem("i18nextLng") || "en";

// Initialize i18next
i18n
  .use(LanguageDetector) // Detects the user's language
  .use(initReactI18next) // Passes i18n instance to react-i18next.
  .init({
    resources: {
      en: {
        accounts: accountsEN,
        notifications: notificationsEN,
        dashboard: dashboardEN,
        layout: layoutEN,
        pages: pagesEN,
        teams: teamsEN,
      },
      nl: {
        accounts: accountsNL,
        notifications: notificationsNL,
        dashboard: dashboardNL,
        layout: layoutNL,
        pages: pagesNL,
        teams: teamsNL,
      },
    },
    lng: savedLanguage, // Initialize with saved language or 'en'
    fallbackLng: "en", // Fallback language
    ns: ["accounts", "notifications", "dashboard", "layout", "pages", "teams"], // Namespaces for each module
    defaultNS: "accounts", // Default namespace
    interpolation: {
      escapeValue: false, // React already escapes output
    },
  });

export default i18n;
